.footer {
    display: flex;
    flex-direction: column;
    width: 100vw;
    padding: 24px 3%;
    background-color: #F6F7FE;
}

.footer h3 {
    margin: 0;
    padding: 0;
    color: #1C38A9;
    font-family: "Red Hat Text";
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
}

.footer span {
    color: #1C38A9;
    font-family: "Red Hat Text";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
}

@media (max-width: 768px) {
    .footer h3 {
        font-size: 12px;
        line-height: 16px;
    }

    .footer span {
        font-size: 12px;
        line-height: 16px;
    }

}