.info-card-container {
    display: flex;
    padding: 24px 0;
    align-items: center;
    gap: 16px;
    border-radius: 20px;
    max-height: 120px;
}

.info-card-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    flex-shrink: 0;
    border-radius: 15px;
    background: #F6F7FE;
    box-shadow: 0px 4px 11px 0px rgba(0, 0, 0, 0.06);
}

.info-card-image-container img {
    width: 36px;
    margin: 0 !important;
}

.info-card-text {
    color: #111928 !important;
    font-family: "Red Hat Text";
    font-size: 16px !important;
    font-style: normal;
    font-weight: 400 !important;
}

.info-card-text span {
    font-weight: 500 !important;
}

@media (max-width: 768px) {
    .info-card-container {
        padding: 8px;
        gap: 8px;
    }

    .info-card-text {
        font-size: 14px !important;
    }
}