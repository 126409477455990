.carousel-container {
    width: 100%;
    overflow: hidden;
    margin-top: 32px;
}

.carousel-wrapper {
    display: flex;
}

.carousel-content-wrapper {
    display: flex;
    width: 100%;
    transition: transform 0.5s ease-out;
    gap: 64px;
}

.carousel-image {
    height: 32px;
}