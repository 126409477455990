.hero-contact-section {
  padding-top: 106px;
  display: flex;
  background-color: #F6F7FE;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.hero-contact-content {
  width: 65%;
  padding-left: 50px;
}

.info-container {
  width: 90%;
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.hero-contact-content img {
  width: 90%;
  margin-bottom: 40px;
}

.hero-contact-content h3 {
  color: #061A84;
  font-family: 'Nunito Sans';
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
  margin: 0 !important;
  padding: 0 !important;
}

.hero-contact-content h2 {
  color: #061A84;
  font-family: 'Nunito Sans';
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 400;
  margin: 0 !important;
  padding: 0 !important;
}

.hero-contact-content h1 {
  color: #061A84;
  font-family: 'Red Hat Text';
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
  line-height: 70px;
  margin: 0 !important;
}

.hero-contact-form-container {
  width: 40%;
  height: 780px;
  padding-right: 3%;
}

.hero-contact-form-container p {
  color: #1C38A9;
  font-family: "Red Hat Text";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 40px;
}

.hero-contact-form-container p strong {
  font-weight: 500;
}

.hero-contact-form {
  width: 100%;
  height: 100%;
  background-color: #FFF;
  border-radius: 16px;
  padding: 48px;
}

@media (max-width: 768px) {
  .hero-contact-section {
    padding-top: 24px;
    flex-direction: column;
  }

  .hero-contact-content {
    width: 100%;
    padding: 0 16px;
  }

  .info-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-bottom: 40px;
  }

  .hero-contact-content img {
    width: 100%;
    margin-bottom: 24px;
  }

  .hero-contact-content h3 {
    font-size: 12px;
  }

  .hero-contact-content h2 {
    font-size: 12px;
  }

  .hero-contact-content h1 {
    font-size: 24px;
    line-height: 32px;
  }

  .hero-contact-form-container {
    width: 100%;
    height: 1000px;
    padding: 0 16px;
  }

}